import { computed, reactive, ref } from 'vue';

import { getGrowthBookFeatureValue, GrowthBookFeatures } from '@/services/growth-book/growth-book';

export function useSplitClearPaywall() {
  const variant = ref<GrowthBookFeatures['clear-paywall']>('def');
  const isInited = ref(false);

  const isV2 = computed(() => variant.value === 'v2');
  const isV3 = computed(() => variant.value === 'v3');
  const isV4 = computed(() => variant.value === 'v4');

  async function init() {
    try {
      variant.value = await getGrowthBookFeatureValue('clear-paywall', 'def');
    } finally {
      isInited.value = true;
    }
  }

  return reactive({
    variant,
    isInited,
    init,
    isV2,
    isV3,
    isV4,
  });
}
