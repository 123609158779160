import { ref } from 'vue';

import { fetchGeo, Geo } from '@/services/geolocation';

export function useCountry() {
  const country = ref<Geo>();
  const isLoaded = ref(false);

  async function load() {
    try {
      country.value = await fetchGeo();
      return country.value;
    } finally {
      isLoaded.value = true;
    }
  }

  return {
    country,
    isLoaded,
    load,
  };
}
