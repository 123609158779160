<template>
  <div class="modal_default" style="display: block">
    <div class="modal_default-dialog">
      <div :class="backgroundClass" class="modal_default-content">
        <div class="modal_default-body">
          <div class="modal_default-trial" :data-test-id="dataTestId">
            <div class="modal_default-close">
              <slot name="close-icon">
                <FontIcon class="icon-close" data-test-id="btn_close" @click="close"></FontIcon>
              </slot>
            </div>

            <div v-if="hasHeaderTitleSlot" class="header-title">
              <slot name="header-title"></slot>
            </div>

            <slot name="title"></slot>

            <PurchaseSliderSubscriptionMoreSlides
              :initial-active="active"
              @click="emit('slider-click')"
            ></PurchaseSliderSubscriptionMoreSlides>

            <slot></slot>
          </div>
        </div>
      </div>
      <div class="trial_after_text">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { hideSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import PurchaseSliderSubscriptionMoreSlides from '@/pages/payment/components/layout/components/purchase-slider-subscription-more-slides/PurchaseSliderSubscriptionMoreSlides.vue';
import { getStore } from '@/store/store-helper';

defineProps({
  backgroundClass: {
    type: String,
    default: '',
  },
  dataTestId: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close', 'slider-click']);

const features: SubscriptionFeature[] = [
  SubscriptionFeature.ADVANCED_INFORMATION,
  SubscriptionFeature.UNLIMITED_LIKES,
  SubscriptionFeature.ADVANCED_SEARCH,
  SubscriptionFeature.UNBLOCK_USERS,
  SubscriptionFeature.UNLIMITED_MESSAGES,
  SubscriptionFeature.DELETE_OWN_MESSAGES,
  SubscriptionFeature.ACTIVITY,
  SubscriptionFeature.ADS,
];

const active = ref(0);

const slots = useSlots();

const hasHeaderTitleSlot = computed(() => {
  return !!slots['header-title']?.({}).length;
});

function close(): void {
  hideSubscriptionPromoModal();
  emit('close');
}

// Created
const index = features.indexOf(
  getStore().state.common.promoModal.feature ?? SubscriptionFeature.UNLIMITED_LIKES,
);
if (index !== -1) {
  active.value = index;
}
</script>
