import { onMounted, onUnmounted } from 'vue';

export function useHistoryChange(handleHistoryChange: () => void) {
  function subscribe() {
    window.addEventListener('popstate', handleHistoryChange);
  }

  function unsubscribe() {
    window.removeEventListener('popstate', handleHistoryChange);
  }

  function init() {
    onMounted(() => {
      subscribe();
    });

    onUnmounted(() => {
      unsubscribe();
    });
  }

  return {
    init,
  };
}
