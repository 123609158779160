<template>
  <template v-if="splitClearPaywall.isV2">
    <div
      :class="{
        '!border-premium border-2 active-popular': isSelected,
      }"
      class="billing_card popular discount best_deal transition-all relative"
      data-test-id="premium-item"
      @click="toggleSelect"
    >
      <div v-show="pkg.isPopular" class="popular_label">
        {{ t('popular') }}
      </div>

      <div class="billing_card-check rounded-full bg-premium">
        <i class="icon icon-success"></i>
      </div>

      <div
        class="w-full flex items-center justify-between relative"
        :class="[isSelected ? 'py-2' : 'py-3', pkg.isPopular ? ' h-20' : 'h-16']"
      >
        <div class="billing_card-body ms-1 flex flex-col gap-1">
          <div class="billing_card-title">{{ pkg.label }}</div>

          <div class="flex items-center gap-1">
            <div
              v-if="packagePricePerWeek.price.totalAmount"
              class="billing_card-price line-through text-dark-100"
            >
              {{ packagePricePerMonthOld }}
            </div>
            <FontIcon class="icon-arrow_right text-dark-100 !text-[16px]" />
            <div class="billing_card-price-green">
              {{ packagePricePerMonthNew }}
            </div>
          </div>
        </div>

        <div class="flex mt-auto">
          <div class="triangle_label bg-green w-24">
            <div class="triangle"></div>
            <div class="triangle_label-body">
              <div class="flex ms-2 font-semibold">
                <span class="text-caption">{{
                  getCurrencySymbol(packagePricePerWeek.price.currency)
                }}</span>
                <span class="mt-[2px] text-headline6">{{ packagePricePerDayInt }}</span>
                <span class="text-caption">{{ packagePricePerDayCents }}</span>
                <span class="text-overline opacity-80 lowercase font-normal mt-3 -ml-[14px]">
                  per day
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="splitClearPaywall.isV3 || splitClearPaywall.isV4">
    <div
      :class="{
        '!border-premium border-2 active-popular': isSelected,
      }"
      class="billing_card popular discount best_deal transition-all relative flex flex-col p-0 clean_paywall"
      data-test-id="premium-item"
      @click="toggleSelect"
    >
      <div
        v-show="pkg.isPopular"
        class="w-full text-overline text-white bg-premium rounded-t-sm text-center transition-all duration-500 ease-in-out"
        :class="isSelected ? 'h-[22px] py-1' : 'h-0 py-0'"
      >
        {{ t('popular') }}
      </div>

      <div
        class="w-full flex items-center justify-between relative h-16 px-4"
        :class="[isSelected ? 'py-2' : 'py-3']"
      >
        <div class="billing_card-check rounded-full bg-premium">
          <i class="icon icon-success"></i>
        </div>

        <div class="billing_card-body ms-1 flex flex-col gap-1">
          <div class="billing_card-title">{{ pkg.label }}</div>
        </div>

        <div class="flex">
          <div
            class="relative right-4 flex text-caption font-medium text-error bg-secondary-500 px-0.5 rounded h-fit line-through"
          >
            {{ packagePricePerMonthOld }}
          </div>
          <div class="triangle_label bg-green w-24 split_clear_paywall justify-center">
            <div class="triangle"></div>
            <div class="triangle_label-body">
              <div class="flex ms-2 font-semibold">
                <span class="text-button2">{{
                  getCurrencySymbol(packagePricePerWeek.price.currency)
                }}</span>
                <span class="mt-[2px] text-headline5">{{ packagePricePerMonthInt }}</span>
                <span class="text-button2">{{ packagePricePerMonthCents }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div
      :class="{
        'popular h-20': pkg.isPopular,
        'active-popular !border-premium border-2': isSelected,
        'h-16': !pkg.isPopular,
      }"
      class="billing_card popular discount best_deal transition-all relative"
      data-test-id="premium-item"
      @click="toggleSelect"
    >
      <div class="billing_card-check rounded-full bg-premium">
        <i class="icon icon-success"></i>
      </div>

      <div v-show="pkg.isPopular" class="popular_label">
        {{ t('popular') }}
      </div>

      <div class="billing_card-body ms-1">
        <div class="billing_card-title">{{ pkg.label }}</div>

        <div
          v-if="packagePricePerWeek.price.totalAmount"
          class="billing_card-price-green text-caption text-dark-200 line-through"
        >
          {{ packagePricePerDayFormatted }}
        </div>
      </div>

      <div
        class="ml-auto triangle_label bg-green !absolute right-4 w-24"
        :class="pkg.isPopular ? 'top-[27px]' : '-translate-y-1/2 top-1/2'"
      >
        <div class="triangle"></div>
        <div class="triangle_label-body">
          <div class="flex ms-2 font-semibold">
            <span class="text-caption">{{
              getCurrencySymbol(packagePricePerWeek.price.currency)
            }}</span>
            <span class="text-headline6 mt-[2px]">{{ packagePricePerDayInt }}</span>
            <span class="text-caption">{{ packagePricePerDayCents }}</span>
            <span class="text-overline opacity-80 lowercase font-normal mt-3 -ml-[14px]"
              >per day</span
            >
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { PremiumPackage } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/package_pb';
import { computed, PropType, reactive } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import { getCurrencySymbol, getFormattedCurrency } from '@/helpers/number';
import { useModel } from '@/hooks/use-model/use-model';
import { getPackagePrice } from '@/pages/payment/helpers/package/package';
import { usePackagePricePerWeek } from '@/pages/payment/hooks/use-package-price/use-package-price';
import { useSplitClearPaywall } from '@/services/growth-book/hooks/use-split-clear-paywall/use-split-clear-paywall';
import { useI18n } from '@/services/i18n';
import { SETTINGS_DEFAULT_CURRENCY } from '@/settings';
import { ExtractClassFields } from '@/type';

import { messages } from './i18n';

const props = defineProps({
  pkg: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ExtractClassFields<PremiumPackage>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const pkgPrice = getPackagePrice(props.pkg);

const splitClearPaywall = useSplitClearPaywall();

const selectedModel = useModel<ExtractClassFields<PremiumPackage>>(props, 'modelValue', emit);

const packagePricePerWeek = reactive(usePackagePricePerWeek(props.pkg));

const packagePricePerMonthNew = computed<string>(() => {
  return getFormattedCurrency(
    pkgPrice.totalAmount ?? 0n,
    pkgPrice.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

const packagePricePerMonthOld = computed<string>(() => {
  return getFormattedCurrency(
    pkgPrice.baseAmount ?? 0n,
    pkgPrice.currency ?? SETTINGS_DEFAULT_CURRENCY,
  );
});

const packagePricePerDayFormatted = computed(() => {
  return getFormattedCurrency(
    packagePricePerWeek.price.baseAmount / 7n,
    packagePricePerWeek.price.currency,
  );
});

const packagePricePerMonthInt = computed(() => {
  return Math.trunc(Number(pkgPrice.totalAmount) / 100);
});

const packagePricePerMonthCents = computed(() => {
  return Number(pkgPrice.totalAmount) % 100;
});

const packagePricePerDayInt = computed(() => {
  return Math.trunc(Number(packagePricePerWeek.price.totalAmount / 7n) / 100);
});

const packagePricePerDayCents = computed(() => {
  return Number(packagePricePerWeek.price.totalAmount / 7n) % 100;
});

const isSelected = computed(() => {
  return selectedModel.value.id === props.pkg.id;
});

function toggleSelect(): void {
  selectedModel.value = props.pkg;
}

splitClearPaywall.init();
</script>
