<template>
  <div
    class="flex items-center transition-all duration-500 ease-in-out overflow-hidden"
    :class="[
      promoTimer.isActive && !isOrganic && !splitClearPaywall.isV4 ? 'h-14' : 'h-0',
      splitNewYear.isV2 ? 'my-3' : 'mb-4 mt-6',
    ]"
    data-test-id="timer_block_top_prp"
  >
    <div class="text-headline6 font-semibold flex-grow flex items-center justify-start">
      {{ t('expires') }}
    </div>
    <div class="flex text-headline4 text-error font-semibold text-center gap-1 font-mono">
      <div>
        {{ promoTimer.timeLeftFormatted.mm }}
        <div class="text-overline text-dark-200 font-medium">{{ t('min') }}</div>
      </div>
      <div>:</div>
      <div>
        {{ promoTimer.timeLeftFormatted.ss }}
        <div class="text-overline text-dark-200 font-medium">{{ t('sec') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onUnmounted } from 'vue';

import { usePromoTimer } from '@/hooks/use-promo-timer/use-promo-timer';
import { useNewYearSplit } from '@/services/growth-book/hooks/use-new-year-split/use-new-year-split';
import { useSplitClearPaywall } from '@/services/growth-book/hooks/use-split-clear-paywall/use-split-clear-paywall';
import { useI18n } from '@/services/i18n';
import { getState } from '@/store/store-helper';

import { messages } from './i18n';

const { t } = useI18n({ messages });

const TIMER_DURATION_SECONDS = 600;
const TIMER_DURATION_RESTART_SECONDS = -1200;

const splitNewYear = useNewYearSplit();
const splitClearPaywall = useSplitClearPaywall();

const promoTimer = usePromoTimer({
  durationSec: TIMER_DURATION_SECONDS,
  durationRestartSec: TIMER_DURATION_RESTART_SECONDS,
  timerName: 'ntf',
});

promoTimer.init();

const { isOrganic } = getState().common.index;

onUnmounted(() => {
  promoTimer.destroy();
});

splitNewYear.init();
splitClearPaywall.init();
</script>
