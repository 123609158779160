<template>
  <div class="modal-backdrop h-full spinner_wrapper">
    <div :class="className" class="modal-dialog modal-dialog-full h-full" role="document">
      <div class="modal-content h-full">
        <div v-if="hasHeaderSlots" class="modal-header justify-center items-center">
          <div class="header-left flex items-center justify-center absolute top-0 h-14 left-[15px]">
            <slot name="left"></slot>
          </div>
          <div class="header-title">
            <slot name="center"></slot>
          </div>
          <div
            class="header-right flex items-center justify-center absolute top-0 h-14 right-[15px]"
          >
            <slot name="right"></slot>
          </div>
        </div>
        <div :class="[className, classNameContent]" class="modal-body flex flex-col">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, useSlots } from 'vue';

defineProps({
  className: {
    type: String,
    default: '',
  },
  classNameContent: {
    type: String,
    default: '',
  },
  isTeleportEnabled: {
    type: Boolean,
    default: true,
  },
});

const slots = useSlots();

const hasHeaderSlots = computed<boolean>(() => {
  return !!slots.left?.({}).length || !!slots.center?.({}).length || !!slots.right?.({}).length;
});
</script>
