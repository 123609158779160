import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';

import { showCreditsOffer } from '@/pages/payment/helpers/credits-offer/credits-offer';
import { CommonStateCreditsFeatures } from '@/store/modules/common/common-state';
import { dispatch, getState } from '@/store/store-helper';

export function hasFreeCreditsFeature(feature: FeatureType): boolean {
  const features: CommonStateCreditsFeatures = getState().common.creditsFeatures;

  return (features[feature] || 0) > 0;
}

export async function isAllowedCreditsAction(
  priceCredits: number,
  feature: FeatureType = FeatureType.INVALID,
): Promise<boolean> {
  if (getState().common.index.isSpecial) {
    showCreditsOffer();
    return false;
  }

  const { isAutorefillAllowed, isAutorefillEnabled, creditsCount } = await dispatch(
    'actionPaymentGetCreditsBalance',
  );
  await dispatch('actionCommonGetFreeCreditsFeaturesStatus');

  const isAutoRefill = isAutorefillAllowed && isAutorefillEnabled;
  const isFreeFeature = feature !== FeatureType.INVALID && hasFreeCreditsFeature(feature);
  const isEnoughCredits = creditsCount >= priceCredits;
  const isAllowed = isAutoRefill || isFreeFeature || isEnoughCredits;

  if (!isAllowed) {
    showCreditsOffer();
  }

  return isAllowed;
}

export function getIsApplePayAvailable(): boolean {
  // @ts-expect-error
  return window.ApplePaySession;
}
