import { computed } from 'vue';

import { UseSnackButton } from '@/components/snack-button/hooks/use-snack-button/type/type';
import { showSubscriptionPromoModal } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/subscription-promo-helper';
import { SubscriptionFeature } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { hasPremium } from '@/helpers/feature/feature';

export function useSnackButtonPremium(): UseSnackButton {
  const action = (): void => {
    showSubscriptionPromoModal(SubscriptionFeature.ADVANCED_SEARCH, 'search-filters');
  };

  const isVisible = computed((): boolean => {
    return !hasPremium();
  });

  return {
    action,
    isVisible,
  };
}
