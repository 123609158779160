<template>
  <div>
    <input
      id="accept"
      v-model="isAgreeChecked"
      type="checkbox"
      class="custom-control-input form-check-input"
    />
    <label class="custom-control-label text-left terms-checkbox-input" for="accept">
      {{ t('accept') }}
      <a @click.prevent="textPageModal.showModal('terms')">{{ t('terms') }}</a>
      ,{{ t('agree') }}
      <a @click.prevent="textPageModal.showModal('privacy')">{{ t('privacy') }}</a>
      ,
      <a @click.prevent="textPageModal.showModal('membership')">{{ t('membership') }}</a>
      ,
      <a @click.prevent="textPageModal.showModal('anti-scam')">{{ t('antiScam') }}</a>
      {{ t('and') }}
      <a @click.prevent="textPageModal.showModal('consent-policy')">{{ t('consentPolicy') }}</a>
    </label>
  </div>
</template>

<script lang="ts" setup>
import { useModel } from '@/hooks/use-model/use-model';
import TextPage from '@/pages/text-page/TextPage.vue';
import { GetTextPageArgumentPageId } from '@/services/api/page/page-api';
import { useI18n } from '@/services/i18n';
import { useModal } from '@/services/modal/modal';

import { messages } from './i18n';

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const { t } = useI18n({ messages });

const isAgreeChecked = useModel<boolean>(props, 'modelValue', emit);

const textPageModal = useModal((pageId: GetTextPageArgumentPageId) => ({
  component: TextPage,
  props: {
    isModal: true,
    pageId,
  },
}));
</script>

<style lang="scss" scoped>
.terms-checkbox-input {
  & > a {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
