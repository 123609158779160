import { getCache, hasCache } from '@/helpers/persistent-cache';

const TRIAL_PROMO_BACKEND_KEY = 'trial_promo_backend';
const IS_AUTOTEST = 'is_autotest';

export function isAutotestTrialPromoBackendDisabled() {
  return hasCache(TRIAL_PROMO_BACKEND_KEY);
}

export function isAutotest() {
  return getCache(IS_AUTOTEST) === 'yes';
}
