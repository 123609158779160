<template>
  <button
    class="button notification-activity_button bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
    @click="emit('action')"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts" setup>
const emit = defineEmits(['action']);
</script>
