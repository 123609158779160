<template>
  <ModalPortal :is-teleport-enabled="false" :background-scroll="false">
    <div
      class="bg-white overflow-hidden shadow-modal-close rounded-lg flex flex-col w-fit absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
    >
      <div class="flex justify-end p-4">
        <i
          class="icon icon-close w-5 h-5 text-[20px] text-dark-100 cursor-pointer"
          @click="emit('close')"
        ></i>
      </div>
      <slot></slot>
    </div>
  </ModalPortal>
</template>

<script lang="ts" setup>
import ModalPortal from '@/components/modal/ModalPortal.vue';

const emit = defineEmits(['close']);
</script>
