<template>
  <div class="modal-popover explanatory d-block" @click="close">
    <div class="modal_default-dialog">
      <div class="modal_default-content">
        <div class="modal_default-body">
          <div
            class="modal_default-trial"
            :data-test-id="isLikesFeature ? 'likes_limit_popup_search' : 'messages_limit_popup'"
          >
            <div class="explanatory-avatar_group flex justify-center">
              <div class="rounded_img">
                <RetinaPhoto
                  v-if="profile"
                  class="rounded-full"
                  :photo-id="profile.avatar.id"
                  :width="64"
                  :height="64"
                />
              </div>
              <div class="rounded_img rounded_img-icon">
                <FontIcon :class="isLikesFeature ? 'icon-like_solid' : 'icon-chat_solid'" />
              </div>
              <div class="rounded_img">
                <RetinaPhoto
                  class="rounded-full"
                  :photo-id="receiverAvatarId"
                  :width="64"
                  :height="64"
                />
              </div>
            </div>
            <div class="explanatory-title text-center">
              <template v-if="isLikesFeature">{{ t('youRunOutOfLikes') }}</template>
              <template v-else>
                {{ t('youRunOutOfMessages') }}
              </template>
            </div>
            <div class="explanatory-txt color-gray-1 text-center text-tag font-medium">
              <template v-if="isLikesFeature">
                {{ t('upgradeYourSubscriptionLikes') }}
              </template>
              <template v-else>
                {{ t('upgradeYourSubscriptionChat') }}
              </template>
            </div>
            <div class="explanatory-btn flex justify-center">
              <LoaderButton class="btn btn-primary mb-0" @click.stop="next">
                {{ isUpgrade ? t('premium') : t('trial') }}
              </LoaderButton>
            </div>

            <div v-if="!isFromLanding" class="text-overline text-dark-200 text-center mt-3">
              <PaymentMethodPolicyTrial
                :price-next="nextPackagePrice"
                :price="price"
                :btn-text="isUpgrade ? t('premium') : t('trial')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PromoType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/payment/v2/promo_pb';
import { computed, PropType } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import LoaderButton from '@/components/inputs/loader-button/LoaderButton.vue';
import PaymentMethodPolicyTrial from '@/components/payment/components/payment-method/components/solid/solid-payment-method-modal-layout/components/payment-method-policy-trial/PaymentMethodPolicyTrial.vue';
import { paymentPurchase } from '@/components/payment/helpers/payment-method/payment-method';
import RetinaPhoto from '@/components/retina-image/RetinaPhoto.vue';
import { SubscriptionPromoModalData } from '@/components/subscription-promo-modal/helpers/subscription-promo-helper/type/type';
import { PromoShowPurchaseSource } from '@/helpers/gtm-triggers/gtm-triggers';
import { useHistoryChange } from '@/hooks/use-history-change/use-history-change';
import { useSubscriptionPromoTrial } from '@/pages/payment/components/purchase/components/purchase-promo/components/subscription-promo-trial/hooks/use-subscription-promo-trial/use-subscription-promo-trial';
import { pushRoute } from '@/router';
import { useI18n } from '@/services/i18n';
import { hideAllModals } from '@/services/modal/modal';
import { getState } from '@/store/store-helper';

import { messages } from './i18n';

const props = defineProps({
  source: {
    type: String as PropType<PromoShowPurchaseSource>,
    required: true,
  },
  promoModalData: {
    type: Object as PropType<SubscriptionPromoModalData>,
    required: true,
  },
});
const emit = defineEmits(['next', 'close']);
const { t } = useI18n({ messages });

const isFromLanding = computed((): boolean => {
  return getState().common.index.isFromLanding;
});

const isLikesFeature = computed(() => {
  return (
    props.source === 'search-insufficient-likes' || props.source === 'profile-insufficient-likes'
  );
});

const receiverAvatarId = computed(() => {
  return props.promoModalData.userReceiver?.avatarId ?? '';
});

const isUpgrade = computed<boolean>(() => {
  return promoModal.value.type !== PromoType.TRIAL;
});

function close(): void {
  emit('close');
}

function next(): void {
  if (!pkg.value) {
    pushRoute('paymentPurchasePremium');
    hideAllModals();
    return;
  }

  paymentPurchase({
    pkg: pkg.value,
  });

  close();

  emit('next');
}

const { profile, pkg, promoModal, handleDataLoading, price, nextPackagePrice } =
  useSubscriptionPromoTrial(emit);

useHistoryChange(close).init();

handleDataLoading(false);
</script>

<style lang="scss" src="./style.scss"></style>
